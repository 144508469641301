<template>
  <div class="login">
    <div class="flex_align_center">
      <div class="login_container flex-justify-between">
        <div class="login_form">
          <div style="text-align:center;">
            <img src="../assets/images/dwellys-logo.svg" alt="logo" />

            <p>Sign in to connect and start managing properties.</p>
          </div>

          <el-form :model="loginForm" ref="loginForm" status-icon :rules="rules">
            <el-form-item prop="email" class="my-3">
              <el-input v-model="loginForm.email" placeholder="yourname@email.com" clearable />
            </el-form-item>
            <el-form-item prop="password" class="my-3">
              <el-input
                v-model="loginForm.password"
                type="password"
                placeholder="**********"
                clearable
              />
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <div class="my-3 center">
              <span @click="resetPasswordVisible = true">
                Forgot your password?
              </span>
            </div>

            <el-button
              class="full-width"
              type="primary"
              :loading="btnLoading"
              @click="login"
              icon="el-icon-mouse"
            >
              Sign In</el-button
            >
          </span>
        </div>
        <div class="login_right_box hidden-sm-and-down">
          <div class="key_features">
            <div class="flex-justify-between">
              <img src="../assets/images/security.svg" alt="icon" />
              <p>
                We deepened our technology beyond the initial search to make the listing debut, the
                escrow process, the whole process, faster, easier and worry - free.
              </p>
            </div>

            <div class="flex-justify-between">
              <img src="../assets/images/security.svg" alt="icon" />
              <p>
                We give customers more value, not j ust by saving each thousand in fees, but by
                measuring our performance and improving constantly.
              </p>
            </div>

            <div class="flex-justify-between">
              <img src="../assets/images/security.svg" alt="icon" />
              <p>
                Dwellys aims towards an efficient property search experience primarily for
                prospective property owners. We aspire to be the best.
              </p>
            </div>
          </div>

          <div class="social_icons">
            <img src="../assets/images/instagram.png" alt="instagram" />
            <img src="../assets/images/facebook.png" alt="fb" />
            <img src="../assets/images/linkedin.png" alt="linkedin" />
          </div>
        </div>
      </div>

      <el-dialog :visible.sync="resetPasswordVisible" width="28%" custom-class="mobile-modal">
        <template slot="title">
          <h2>Reset Password</h2>
          <p>Enter the email address associated with your account.</p>
        </template>
        <el-form
          :model="resetForm"
          ref="resetForm"
          status-icon
          :rules="rules"
          label-position="top"
          style="margin: -40px 0 -30px 0"
        >
          <el-form-item prop="email" class="my-3" label="Email">
            <el-input v-model="resetForm.email" placeholder="yourname@email.com" clearable />
          </el-form-item>
        </el-form>
        <span slot="footer">
          <el-button
            class="full-width"
            type="primary"
            :loading="btnLoading"
            @click="resetPassword"
            icon="el-icon-mouse"
          >
            Continue</el-button
          >
        </span>
      </el-dialog>
    </div>
    <div class="login-footer hidden-sm-and-down">
      <a class="link">© 2020.</a>
      <a class="link">Privacy</a>
      <a class="link">Legal</a>
      <a class="link">Terms & Conditions</a>
      <a class="link">Support</a>
    </div>
  </div>
</template>

<script>
import authApi from "@/api/auth";

export default {
  name: "Login",
  data() {
    return {
      resetPasswordVisible: false,
      btnLoading: false,
      resetForm: {
        email: ""
      },
      loginForm: {
        email: "",
        password: ""
      },
      rules: {
        email: [
          {
            required: true,
            type: "email",
            message: "Please enter valid email",
            trigger: ["blur", "change"]
          },
          { min: 5, message: "Length should be 5 or more", trigger: "blur" }
        ],
        password: [
          { required: true, message: "Please enter password", trigger: "blur" },
          {
            min: 6,
            message: "Length should be 6 or more characters",
            trigger: "change"
          }
        ]
      }
    };
  },
  methods: {
    login() {
      let self = this;
      this.btnLoading = true;
      this.$refs["loginForm"].validate(valid => {
        if (valid) {
          authApi
            .login(this.loginForm)
            .then(response => {
              if (response.message == "error") {
                this.infoMessage(response.data);
              } else {
                this.infoMessage(response.message);
              }
              self.set_user(response.data);
            })
            .catch(error => {
              this.errorMessage(error.message);
              this.btnLoading = false;
            });
        } else {
          this.btnLoading = false;
          return false;
        }
      });
    },
    set_user(response) {
      this.$store
        .dispatch("login", response)
        .then(() => {
          this.btnLoading = false;
          this.$router.push({ name: "Dashboard" });
        })
        .catch(() => this.errorMessage("Token unidentified, contact administrator"));
    },
    resetPassword() {
      console.log(this.resetForm);
      authApi
        .forgetPassword(this.resetForm)
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          this.errorMessage(error.message);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.login {
  height: 100vh;
  background: rgb(21, 26, 81);
  background: linear-gradient(
    173deg,
    rgba(21, 26, 81, 0.9738270308123249) 7%,
    rgba(27, 38, 96, 1) 48%,
    rgba(17, 23, 71, 0.9402135854341737) 77%,
    rgba(24, 43, 88, 0.9374124649859944) 95%,
    rgba(51, 68, 167, 0.9962359943977591) 98%
  );
  .login-footer {
    background-color: whitesmoke;
    text-align: center;
  }
}

.login_container {
  background-color: whitesmoke;
  border-radius: 10px;
  padding: 25px;
  width: 60%;

  .login_form {
    width: 100%;
  }

  .login_right_box {
    .social_icons img {
      width: 25px;
      margin-left: 10px;
      float: right;
    }

    .key_features {
      padding: 40px;
      width: 100%;

      img {
        margin-right: 10px;
      }
    }
  }

  p {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 15px;
  }
}
</style>
