import apiService from './api'
import config from '../config'

export default {
  name: 'authApi',
  components: {
    apiService
  },

  login(requestForm) {
    return apiService.post(config.LOGIN_URL, requestForm)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  forgetPassword(email) {
    return apiService.post(config.FORGET_PASSWORD_URL, email)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  }
}